
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { RestEndpointMethodTypes } from '@octokit/rest';
import GitHubLogin from '@/components/GitHubLogin.vue';
import ghService from '@/components/github-auth/gitHubLoginService';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { sanitize } from '@/utils/components';

type Repo = RestEndpointMethodTypes['repos']['get']['response']['data'];

@Component({
  components: {
    GitHubLogin,
    Promised,
  },
})
export default class ProjectCICDRepos extends Vue {
  @Prop({ type: Number, required: true })
  projectEnvId!: number;
  @Prop({ type: Number, required: true })
  projectId!: number;
  public sanitizeLink = (source:string) => sanitize(source);
  loggedIn = false;
  get loadRepo(): Promise<Repo> | null {
    if (!this.loggedIn) {
      return null;
    }

    const repoId =
      ProjectDetailsModule.envs[this.projectId][this.projectEnvId].connections.GITHUB
        .connectionDetails;

    if (ghService.octokit) {
      return (ghService.octokit as any)
        .request('GET /repositories/:id', repoId)
        .then((res: any) => res.data);
    }

    return null;
  }
}
