
import { RawLocation } from 'vue-router';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    GButton,
  },
})
export default class EmptyState extends Vue {
  @Prop({ type: String, default: '' }) buttonText!: string;
  @Prop({ type: Object, default: null }) link!: RawLocation | null;
  @Prop({ type: String, default: '/img/shapes.svg' }) imageSrc!: string;
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: '' }) subtitle!: string;
}
