
import { Component, Prop, Vue } from 'vue-property-decorator';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { formatDuration, intervalToDuration } from 'date-fns';
import { Promised } from 'vue-promised';
import NavigationTabs from '@/components/NavigationTabs.vue';
import AzLogin from '@/components/AzLogin.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import AzureLoginService from '@/components/Auth/auth.service';
import { fetchMsGraph } from '@/api/auth/authUtils';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import EmptyState from '../EmptyState.vue';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { sanitize } from '@/utils/components';

interface AdoInfo {
  organizationName: string;
  projectId: string;
  id: number;
}

interface AdoListContainer<T> {
  count: number;
  value: T[];
}

interface PipelineRun {
  _links: PipelineLinks;
  pipeline: PipelineRunPipeline;
  state: 'canceling' | 'completed' | 'inProgress' | 'unknown';
  result: 'canceled' | 'failed' | 'succeeded' | 'unknown';
  createdDate: string;
  finishedDate: string;
  url: string;
  id: number;
  name: string;
}
interface PipelineLinks {
  self: Href;
  web: Href;
  'pipeline.web': Href;
  pipeline: Href;
}
interface Href {
  href: string;
}
interface PipelineRunPipeline {
  url: string;
  id: number;
  revision: number;
  name: string;
  folder: string;
}

@Component({
  components: {
    NavigationTabs,
    AzLogin,
    GButton,
    Promised,
    EmptyState,
  },
})
export default class ProjectPipeline extends Vue {
  @Prop({ type: Number, required: true }) projectEnvId!: number;
  @Prop({ type: Number, required: true }) projectId!: number;
  public santizieLink = (source: string) => sanitize(source);
  loggedIn = false;
  public sanitizeLink = (source:string) => sanitize(source);
  azService = new AzureLoginService();
  now() {
    return new Date().toString();
  }

  timeToNow(dateString: string) {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  }

  duration(startDateString: string, finDateString: string) {
    const interval = intervalToDuration({
      start: new Date(startDateString),
      end: new Date(finDateString),
    });
    return formatDuration(interval);
  }

  openDevops() {
    const msDevAzureUrl = ProcessEnvsModule.processEnvs.msDevAzureUrl;
    const data =
      ProjectDetailsModule.envs[this.projectId][this.projectEnvId].connections.PIPELINE
        .connectionDetails;
    window.open(`${msDevAzureUrl}/${data.organizationName}/${data.projectId}`);
  }

  listPipelines(data: AdoInfo) {
    const msDevAzureUrl = ProcessEnvsModule.processEnvs.msDevAzureUrl;
    const url = `${msDevAzureUrl}/${data.organizationName}/${data.projectId}/_apis/pipelines?api-version=6.1-preview.1`;
    fetchMsGraph<AdoListContainer<unknown>>(url, this.azService.getToken('ado')).then(this.$log);
  }

  getPipeline(data: AdoInfo) {
    const msDevAzureUrl = ProcessEnvsModule.processEnvs.msDevAzureUrl;
    const url = `${msDevAzureUrl}/${data.organizationName}/${data.projectId}/_apis/pipelines/${data.id}?api-version=6.1-preview.1`;
    fetchMsGraph(url, this.azService.getToken('ado')).then(this.$log);
  }

  runs: PipelineRun[] = [];
  get listRuns(): Promise<PipelineRun[]> | null {
    if (!this.loggedIn) {
      return null;
    }
    const data =
      ProjectDetailsModule.envs[this.projectId][this.projectEnvId].connections.PIPELINE
        .connectionDetails;
    const msDevAzureUrl = ProcessEnvsModule.processEnvs.msDevAzureUrl;
    const url = `${msDevAzureUrl}/${data?.organizationName}/${data?.projectId}/_apis/pipelines/${data?.id}/runs?api-version=6.1-preview.1`;
    return fetchMsGraph<AdoListContainer<PipelineRun>>(url, this.azService.getToken('ado')).then(
      data => data.value.slice(0, 10),
    );
  }
}
