
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import ProjectRepos from '@/components/project-details/ProjectRepos.vue';
import ProjectPipeLine from '@/components/project-details/ProjectPipeLine.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { EnumsModule } from '@/store/modules/enums.module';
import GButton from '@/components/gsk-components/GskButton.vue';
import EmptyState from '@/components/EmptyState.vue';
import { RouteNames } from '@/constants';

let timer: number;

const wait = (n: number) =>
  new Promise(res => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(res, n);
  });

@Component({
  components: {
    ProjectPipeLine,
    ProjectRepos,
    Grid,
    GridCell,
    GButton,
    EmptyState,
  },
  beforeRouteLeave(to, from, next) {
    if (timer) {
      clearTimeout(timer);
    }
    return next();
  },
})
export default class ProjectCicd extends Vue {
  get catalogLink(): RawLocation {
    return {
      name: RouteNames.ListingType,
      params: {
        type: 'cicd',
      },
    };
  }

  get cicdProjectEnv() {
    return ProjectDetailsModule.projectDetails.environments.find(
      env => env.environmentTypeMnemonic === 'CICD',
    );
  }
  get cicdProjectEnvId() {
    return this.cicdProjectEnv?.projectEnvironmentId;
  }
  get noEnv() {
    return !this.cicdProjectEnv;
  }

  get provisioning() {
    const id = this.cicdProjectEnv?.statusId;
    if (id) {
      return EnumsModule.enumsById.status[id].mnemonic === 'PROVISIONING';
    }
    return false;
  }

  get failed() {
    const id = this.cicdProjectEnv?.statusId;
    if (id) {
      return EnumsModule.enumsById.status[id].mnemonic === 'FAILED';
    }
    return false;
  }

  get projectId() {
    return +this.$route.params.id;
  }

  async load() {
    if (this.noEnv) {
      return;
    }
    await ProjectDetailsModule.getProjectEnvironmentPart({
      projectId: this.projectId,
      // we'll disable navigation to this route when no cicd env exists
      projectEnvId: this.cicdProjectEnvId || -1,
      partName: 'connections',
    });
  }

  async created() {
    while (this.provisioning) {
      await wait(10000);
      await ProjectDetailsModule.baseGetProject(this.projectId);
    }
    await this.load();
  }
}
